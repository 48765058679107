html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  color: #333;
  background: #fff;
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin-bottom: 0;
}
a {
  color: #333;
}
a:hover,
a:focus {
  color: #d73630;
  text-decoration: none;
}
ul {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}
.bg-light {
  background: #fff !important;
}

/* my css */
header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  background: #ddd;
}
.topHeader {
  background-color: #333;
  color: #fff;
  width: 100%;
  position: block;
  text-align: center;
}
.topHeader ul li {
  display: inline-grid;
}
.topHeader ul li a {
  padding: 5px 15px;
  color: #fff;
  border-right: 1px solid #fff;
}
.navbar {
  box-shadow: 0 0 15px #00000047;
}
.navbar-expand-lg .navbar-nav .nav-link {
  text-transform: uppercase;
  font-weight: 600;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #d73630;
}

footer .mainFooter {
  padding: 35px 15px;
  background-color: #333;
  color: rgb(201, 201, 201);
}
footer h5 {
  color: #fff;
}
footer a {
  color: rgb(201, 201, 201);
}
footer .footer-list-menu {
  padding-left: 15px;
}
.footer .footer-widget .footer-list-menu li:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  left: 0;
  top: 12px;
  background: #d73630;
  border-radius: 100%;
}

.design a {
  display: inline-grid;
  box-shadow: 0px 0px 2px #d73630;
  border-bottom: 2px solid #d73630;
  text-align: center;
  padding: 5px 10px;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  margin: 2px 2px;
}
.design a:hover {
  background-color: #d73630;
}

.social-icons {
  margin: 0;
  padding: 0;
  width: auto;
}
.social-icons li {
  display: inline-block;
  margin: -1px 1px 5px 0;
  padding: 0;
  border-radius: 100%;
  overflow: visible;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 30%);
  margin-right: 3px;
}
.social-icons li.social-icons-facebook a {
  background: #3b5a9a;
}
.social-icons li.social-icons-twitter a {
  background: #1aa9e1;
}
.social-icons li.social-icons-linkedin a {
  background: #0073b2;
}
.social-icons li.social-icons-instagram a {
  background: #7c4a3a;
}
.social-icons li a {
  background: #171717;
  color: #fff !important;
  font-size: 25px;
}
.social-icons li a {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: #fff;
  border-radius: 100%;
  display: block;
  height: 50px;
  line-height: 50px;
  width: 50px;
  text-align: center;
  text-decoration: none;
}
.social-icons li a:hover {
  opacity: 0.8;
}
.bg_copyright {
  background-color: rgb(65, 65, 65);
  color: #6b6b6b;
  padding: 20px 0;
}
.footer .copyright-section {
  background-color: rgb(65, 65, 65);
  color: #6b6b6b;
  padding: 20px 0;
}

main {
  min-height: 550px;
  margin: 99px 0 50px 0;
}

/* about page */
.vdo-thumb {
  position: relative;
  display: block;
  border-radius: 5px;
}
.vdo-thumb::before {
  font: normal normal normal 60px/1 FontAwesome;
  content: "\f04b";
  position: absolute;
  width: 50px;
  height: 50px;
  color: rgba(255, 255, 255, 0.6);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 0 5px 15px rgb(0 0 0 / 50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
}
.vdo-thumb img {
  border-radius: 5px;
  width: 100%;
}

.left-icon-style {
  margin-top: 30px;
}

.left-icon-style i {
  font-size: 22px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #fff;
  color: #d73630;
  box-sizing: content-box;
  text-align: center;
  border: 2px solid #d73630;
  border-radius: 50%;
}
.left-icon-style:hover i {
  color: #fff;
  background: #d73630;
}
.left-icon-style .media-left {
  padding-right: 30px;
}

.left-icon-style p {
  margin: 0;
}
.left-icon-style .media-heading {
  font-size: 18px;
}

/* career page */
.careerTab .tab-content {
  padding: 25px;
}
.careerTab .tab-content .tab-pane .card {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-top: 5px solid #d73630;
  margin-bottom: 35px;
  min-height: 225px;
  padding: 10px;
}
.careerTab .tab-content .tab-pane .card .card-body{
  padding: 10px;
}
.careerTab .tab-content .tab-pane .card .card-title {
  font-weight: 600;
  font-size: 18px;
}
.careerTab .tab-content .tab-pane .card button {
  margin-top: 15px;
}

/* virtual assistant page */
.bg_gray {
  background: #eee;
  border-radius: 15px;
  padding: 50px 0 3px 0;
  box-shadow: 0 2px 5px #33333385;
  min-height: 320px;
  margin: 50px 0 3px 0;
}
.circle_img {
  height: 70px;
  width: 70px;
  border: 2px solid #d73630;
  border-radius: 50%;
  padding: 7px;
  background-color: #fff;
  margin: auto;
  margin-top: -90px;
}
.service_title{
  padding: 0 15px;
}
.services_ul {
  list-style: square !important;
  padding: 0 15px;
}
.services_ul li {
  font-size: 14px;
  display: flex;
  list-style-position: inside;
}
.fa_sqare {
  font-size: 6px;
  color: #333;
  margin: 8px 10px 5px 5px;
}
.nohover:hover {
  color: #333333;
}
.box_title {
  text-transform: capitalize;
}

/* blog page */
.blogs .card-img,
.card-img-top {
  max-height: 210px;
}
.blogs .card-body {
  padding: 15px !important;
}
.blogs .card-title {
  text-overflow: ellipsis;
  height: 27px;
  overflow: hidden;
  white-space: nowrap;
}
.blogs .card{
  transition: all 0.2s ease-in;
}
.blogs .card:hover{
  color: #333;
  box-shadow: 1px 1px 10px #00000030;
  display: block;
  border-radius: 5px;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
}
/* service page */
.red-top-border_s {
  border-top: 6px solid #f44336;
}
.white_bg {
  background: #fff !important;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}
.white_bg .caption {
  padding: 25px;
}
.service_ul {
  list-style: disc;
  padding: 0 0 0 25px;
}
.service_ul li {
  list-style: disc;
  display: list-item;
  color: #fc2d47;
  font-size: 14px;
}
.red-shadow:hover {
  box-shadow: rgb(244 67 54 / 20%) 0 2px 2px, rgb(244 67 54 / 20%) 0 4px 4px,
    rgb(244 67 54 / 20%) 0 8px 8px, rgb(244 67 54 / 20%) 0 16px 16px,
    rgb(244 67 54 / 20%) 0 32px 32px, rgb(244 67 54 / 20%) 0 64px 64px;
}
.green-top-border_s {
  border-top: 6px solid #009688;
}
.green-shadow:hover {
  box-shadow: rgb(0 150 136 / 20%) 0 2px 2px, rgb(0 150 136 / 20%) 0 4px 4px,
    rgb(0 150 136 / 20%) 0 8px 8px, rgb(0 150 136 / 20%) 0 16px 16px,
    rgb(0 150 136 / 20%) 0 32px 32px, rgb(0 150 136 / 20%) 0 64px 64px;
}
.blue-top-border_s {
  border-top: 6px solid #2196f3;
}
.blue-shadow:hover {
  box-shadow: rgb(33 150 243 / 20%) 0 2px 2px, rgb(33 150 243 / 20%) 0 4px 4px,
    rgb(33 150 243 / 20%) 0 8px 8px, rgb(33 150 243 / 20%) 0 16px 16px,
    rgb(33 150 243 / 20%) 0 32px 32px, rgb(33 150 243 / 20%) 0 64px 64px;
}

/* Home Page */
.landing {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.action-content {
  display: table;
  width: 100%;
}
.action-content .action-text,
.action-content .action-button {
  display: table-cell;
  vertical-align: middle;
}
.action-text {
  text-align: left;
  color: #000;
}
.action-text h1 {
  color: #fff;
  font-size: 30px;
  margin: 0;
}
.action-content .action-button {
  text-align: right;
}
.action-button .btn-default {
  background: transparent;
  border-color: #fff;
  color: #fff;
}
.call-to-action-variant-1 {
  background: #211f1f;
  padding: 25px 0;
}
.action-button .btn-default:hover {
  color: #fff;
  background-color: #d73630;
  border-color: #d73630;
}
.values_section {
  /* background-image: url("../public/assets/img/background21.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 80%;
  background-attachment: fixed;
  padding: 20px 0;
  position: relative;
  background-color: #d73630;
}
.per_wrap {
  display: flex;
}
.right {
  border-bottom: unset;
}
.wrap_img {
  float: left;
  position: relative;
  padding: 10px;
  border-right: 1px solid #ffffff;
}
.wrap_img img {
  width: 50px;
  height: 60px;
  color: #ffffff;
}
.wrap_box {
  float: left;
  position: relative;
  z-index: 9;
  color: #ffffff;
  padding: 10px;
}
.wrap_box p {
  font-size: 18px;
  color: #fff;
  z-index: 3;
  margin: 0;
}
.wrap_box h5 {
  font-size: 23px;
  color: #fff;
}

.how-can_section {
  min-height: 450px;
}
.main_title {
  font-size: 30px;
  margin-bottom: 20px;
  color: #333;
  line-height: 1.3;
}
.how-can_section_h4 {
  text-align: center;
  color: #333;
}
.icon {
  border-radius: 50%;
  border: 2px solid #d73630;
  width: 95px;
  text-align: center;
  margin: 0 auto 15px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon img {
  height: 60px;
  width: 60px;
  cursor: pointer;
}
.box .box_title {
  font-size: 16px;
  margin-bottom: 15px;
}
.icon:hover {
  background: #d73630;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
}
.thumbnail-variant-1 {
  padding: 0;
  height: 500px;
  border-radius: 0;
  margin-bottom: 30px;
  border-width: 6px 0 0 0;
  transition: all 0.2s linear;
  background: #fff;
}
.thumbnail-variant-1 .caption {
  padding: 30px 30px 20px;
}
.thumbnail-variant-1 .caption h3 {
  text-transform: capitalize;
  margin: 0 0 15px;
  font-weight: 600;
}
.thumbnail-variant-1 img {
  width: 100%;
}
.section-title p {
  font-size: 22px;
  color: cadetblue;
  font-weight: 600;
}
.gray-bg {
  background-color: #f9f9f9;
}
.btn-link {
  padding: 0;
  border: 0;
  font-size: 15px;
  color: #d73630;
}
.category-btn {
  display: flex;
  justify-content: center;
  margin: 30px 0 40px;
}
.category-btn li {
  margin: 10px;
}
.category-btn li a {
  padding: 5px 15px;
  background: #fff;
  color: #d73630;
  border: 2px solid #d73630;
  font-size: 15px;
  border-radius: 5px;
  transition: 0.3s ease;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.category-btn li a:hover {
  opacity: 0.8;
}

.category-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  max-width: 100%;
  margin: auto;
}
.category-list li {
  width: calc(33.3333% - 40px);
  margin: 10px;
  border: 1px solid #bbb;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.category-list li:hover {
  opacity: 0.7;
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-shadow: 0 0 20px #00000073;
}
.category-list li img {
  width: 100%;
  display: block;
  max-height: 400px;
}

.is-animate {
  opacity: 1;
  animation: 0.6s zoom-in;
}

@keyframes zoom-in {
  0% {
    opacity: 0.3;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

/* personal assistant  */
.breadcrumb_bg {
  background-color: #eee;
}
ul.va_breadcrumb {
  padding: 5px 10px;
  list-style: none;
  background-color: #eee;
  margin-bottom: 0px;
}
ul.va_breadcrumb li {
  display: inline;
  font-size: 14px;
}
ul.va_breadcrumb li + li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}
ul.va_breadcrumb li a {
  color: #0275d8;
  text-decoration: none;
}
.service_name h1 {
  font-size: 20px;
  padding-left: 10px;
  font-weight: 600;
  color: #fc2d47;
  margin-bottom: 0;
  line-height: 30px;
}
.service_name h2 {
  font-size: 16px;
  padding-left: 10px;
  font-weight: 200;
}
.call_btn {
  text-align: right;
}
.call_btn a {
  background: #fc2d47;
  font-size: 14px;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
}
.box {
  text-align: center;
}
.child_services p {
  font-size: 16px;
  font-weight: 600;
  padding-left: 10px;
  margin-bottom: 0;
  text-decoration: underline;
}
.box-part {
  background: #eee;
  border-radius: 0;
  padding: 15px;
  margin: 17px 0px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px #33333352;
  height: 190px;
}
.container .title {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}
.service_d_hr {
  margin-top: 5px;
  margin-bottom: 5px;
  border: 0;
  border-top: 1px solid #3333;
}
.title h4 {
  font-size: 16px;
  line-height: 20px;
  color: #333;
}
.text span {
  font-size: 14px;
}
.box-part:hover {
  box-shadow: 0px 7px 12px rgb(119 115 115 / 55%);
}

.section-statistics {
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  padding: 50px 0;
  position: relative;
}
.section-statistics::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.h2_fff h2 {
  color: #fff;
}
.tech_section {
  background-color: #eee;
}
.ptb-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.main_title {
  font-size: 30px;
  margin-bottom: 20px;
  color: #333;
  line-height: 1.3;
}
.techCarousel .item img {
  width: 150px;
  display: block;
  padding: 0 15px;
}
.value_section {
  min-height: 450px;
}
.value_section .title {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}
.value_section .img {
  margin: 10px auto;
  height: 60px;
  width: 60px;
}
.value_cnt {
  height: 60px;
  font-size: 13px;
}
.value_box {
  text-align: center;
  position: relative;
}

/* Home section Design */
.credit {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #000;
}
body .options {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  max-width: 800px;
  width: calc(100% - 10px);
  height: 430px;
  margin: 50px auto;
}
/* @media screen and (max-width: 718px) {
  body .options {
    min-width: 520px;
  }
  body .options .option:nth-child(5) {
    display: none;
  }
}
@media screen and (max-width: 638px) {
  body .options {
    min-width: 440px;
  }
  body .options .option:nth-child(4) {
    display: none;
  }
}
@media screen and (max-width: 558px) {
  body .options {
    min-width: 360px;
  }
  body .options .option:nth-child(3) {
    display: none;
  }
}
@media screen and (max-width: 478px) {
  body .options {
    min-width: 280px;
  }
  body .options .option:nth-child(2) {
    display: none;
  }
} */
body .options .option:nth-child(1) {
  background: url(./images/webdesign2.jpg);
}
body .options .option:nth-child(2) {
  background: url(./images/degitalmarketing.jpg);
}
body .options .option:nth-child(3) {
  background: url(./images/webdev.png);
}
body .options .option:nth-child(4) {
  background: url(./images/itsolution.jpg);
}
body .options .option:nth-child(5) {
  background: url(./images/webdesign2.jpg);
}
@media screen and (max-width: 786px) {
  body .options .option {
    min-width: 30px !important;
  }
}
body .options .option {
  position: relative;
  overflow: hidden;
  min-width: 60px;
  margin: 5px;
  background: var(--optionBackground, var(--defaultBackground, #e6e9ed));
  background-size: auto 120%;
  background-position: center;
  cursor: pointer;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}
body .options .option:nth-child(1) {
  --defaultBackground: #ed5565;
}
body .options .option:nth-child(2) {
  --defaultBackground: #fc6e51;
}
body .options .option:nth-child(3) {
  --defaultBackground: #ffce54;
}
body .options .option:nth-child(4) {
  --defaultBackground: #2ecc71;
}
body .options .option:nth-child(5) {
  --defaultBackground: #5d9cec;
}
body .options .option:nth-child(6) {
  --defaultBackground: #ac92ec;
}
body .options .option.active {
  flex-grow: 10000;
  transform: scale(1);
  min-width: 300px;
  width: calc(100% - 100px);
  margin: 0px;
  border-radius: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  /*&:active {
     transform:scale(0.9);
  }*/
}
body .options .option.active .shadow {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8018557764902836) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
body .options .option.active .label .icon {
  transform: rotateZ(360deg);
  -webkit-transform: rotateZ(360deg);
  -moz-transform: rotateZ(360deg);
  -ms-transform: rotateZ(360deg);
  -o-transform: rotateZ(360deg);
}
body .options .option.active .label {
  bottom: 45%;
  left: 0;
  background: #0000009e;
  padding: 5px;
  justify-content: center;
}
body .options .option.active .label .info > div {
  left: 10px;
  opacity: 1;
}
body .options .option:not(.active) {
  flex-grow: 1;
  border-radius: 30px;
}
body .options .option:not(.active) .shadow {
  bottom: -40px;
  box-shadow: inset 0 -120px 0px -120px black, inset 0 -120px 0px -100px black;
}
body .options .option:not(.active) .label {
  bottom: 10px;
  left: 10px;
}
body .options .option:not(.active) .label .info > div {
  right: 30px;
  top: -35px;
  transform: scaleX(-1);
  position: absolute;
}
body .options .option.active .label .info {
  transform: unset;
  -webkit-transform: unset;
  -moz-transform: unset;
  -ms-transform: unset;
  -o-transform: unset;
  transform-origin: unset;
}
body .options .option .shadow {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 120px;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}
body .options .option .label {
  display: flex;
  position: absolute;
  align-items: center;
  right: 0px;
  height: 40px;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}
body .options .option .label .icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  font-size: 20px;
  border-radius: 100%;
  background-color: white;
  color: var(--defaultBackground);
  margin: unset !important;
  transform: rotateZ(270deg);
}
body .options .option .label .info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  white-space: pre;
  /* position: absolute; */
  transform: rotate(-90deg) scaleX(-1);
  transform-origin: top left;
  -webkit-transform: rotate(-90deg) scaleX(-1);
  -moz-transform: rotate(-90deg) scaleX(-1);
  -ms-transform: rotate(-90deg) scaleX(-1);
  -o-transform: rotate(-90deg) scaleX(-1);
}
body .options .option .label .info > div {
  position: relative;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity 0.5s ease-out;
}
body .options .option .label .info .main {
  font-weight: bold;
  font-size: 1.2rem;
}
body .options .option .label .info .sub {
  transition-delay: 0.1s;
}
.category-btn li a.active {
  background: #d73630;
  color: #fff;
}

@media (max-width: 786px) {
  main {
    margin: 65px 0 50px 0 !important;
  }
  .category-list li {
    width: calc(46.3333% - 40px);
    margin: 10px;
    border: 1px solid #bbb;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
  }
  .category-btn li a {
    padding: 5px 10px;
    font-size: 13px;
  }
  .category-btn li {
    margin: 3px;
  }
  .category-btn {
    display: flex;
    justify-content: center;
    margin: 30px 0 25px;
  }
  body .options .option .label .icon {
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    font-size: 20px;
  }
  body .options .option:not(.active) .label {
    bottom: -4px;
    left: 3px;
  }
  /* body .options .option.active {
    max-width: 320px;
  } */
  body .options .option {
    margin: 2px;
  }
  body .options {
    height: 430px;
  }
  body .options .option .shadow {
    height: 85px;
  }
}
.contactBtn {
  min-width: 48px;
}
.contactBtn i{
  font-size: 21px;
}
.fullscreenMenu {
  position: absolute;
  top: 40px;
  right: 140px;
  z-index: 999;
  display: inline;
  font-size: 40px;
}
.fullscreenMenuOpen{
  display: block;
}
.fullscreenMenu a {
  display: inline-block;
  text-decoration: none;
}

.overlay {
  position: fixed;
  top: 95px;
  left: 0;
  z-index: 99;
  display: none;
  overflow: auto;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 88%;
  background: #9e2420;
}
.fullscreenMenuName {
  width: 100%;
  height: 100%;
}
ul,
li {
  list-style: none;
  padding: 0;
}
.fullscreenMenuName div {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
.fullscreenMenuName a {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #fff;
  color: #fff;
  transition: all 0.35s ease;
}
.fullscreenMenuName a p {
  padding: 5px 20px;
  color: #fff;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  line-height: 1.2 !important;
  text-align: center;
}
.fullscreenMenuName li a {
  display: flex;
  color: #fff;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/* RESPONSIVE */
@media screen and (max-width: 768px) {
  .fullscreenMenu {
    right: 10px;
    top: 10px;
  }
  .fullscreenMenuName a:hover {
    box-shadow: inset 0 0 0 8px rgb(255 255 255 / 75%) !important;
    transition: all 0.35s ease;
  }
  .fullscreenMenuName a p {
    padding: 5px;
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    margin: 0;
    line-height: 1.2 !important;
    text-align: center;
  }
}

.allexamples {
  position: absolute;
  bottom: 0;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  background: #e9e9e9;
  padding: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #333;
  position: fixed;
}

.flood-right {
  -webkit-transition: 0.35s ease-in-out;
  -moz-transition: 0315s ease-in-out;
  -ms-transition: 0.35s ease-in-out;
  -o-transition: 0.35s ease-in-out;
  transition: 0.35s ease-in-out;
  -webkit-box-shadow: inset 0px 0 0 0 #0056c1;
  -moz-box-shadow: inset 0px 0 0 0 #0056c1;
  -ms-box-shadow: inset 0px 0 0 0 #0056c1;
  -o-box-shadow: inset 0px 0 0 0 #0056c1;
  box-shadow: inset 0px 0 0 0 #0056c1;
  color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.fullscreenMenuName a:hover .flood-right {
  color: white;
  -webkit-box-shadow: inset 100vw 0 0 0 #0056c1;
  -moz-box-shadow: inset 100vw 0 0 0 #0056c1;
  -ms-box-shadow: inset 100vw 0 0 0 #0056c1;
  -o-box-shadow: inset 100vw 0 0 0 #0056c1;
  box-shadow: inset 100vw 0 0 0 #0056c1;
}
a:hover {
  text-decoration: none;
}
.fullscreenMenuName a:hover {
  box-shadow: inset 0 0 0 10px rgb(255 255 255 / 75%);
  transition: all 0.35s ease;
}
.fullscreenMenuName a .link {
  outline: none;
  text-decoration: none;
  position: relative;
  line-height: 1;
  color: #fff;
  display: inline-block;
}
.fullscreenMenuName a .link--kukuri {
  text-transform: uppercase;
  font-weight: 900;
  overflow: hidden;
  line-height: 0.75;
  color: #fff;
}
.fullscreenMenuName a:hover .link--kukuri {
  color: #ffbc00;
}
.humburger {
  height: 48px;
  width: 48px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #d73630;
}
.humburger:hover{
  border: 1px solid #dc3545;
}

.humburger .l1,
.humburger .l2,
.humburger .l3 {
  position: absolute;
  display: block;
  margin: auto;
  top: 48%;
  left: 15%;
  height: 3px;
  width: 70%;
  background-color: #d73630;
  transition: all 0.2s ease-out;
}
.humburger .l1 {
  top: 23%;
}
.humburger .l3 {
  top: 73%;
}
.humburger.active .l1 {
  transform: rotate(45deg);
  top: 48%;
}
.humburger.active .l2 {
  opacity: 0;
}
.humburger.active .l3 {
  transform: rotate(-45deg);
  top: 48%;
}

.bgImage {
  height: auto;
  width: 100%;
  float: left;
  position: relative;
  transform: translateY(0) rotateY(0);
  opacity: 1;
}
.bgImage:after {
  content: "";
  /* background: url(../img/2.jpg) center; */
  background-size: cover;
  opacity: 0.3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.centerSlider .shadow{
  background: #10315b69 !important;
  position: absolute;
    bottom: 0px;
    left: 0px;
    top: 0;
    right: 0px;
    height: 100% !important;
}

@media screen and (max-width: 767px) {
  .fullscreenMenuName div {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .humburger {
    height: 48px;
    width: 48px;
    display: block !important;
    border-radius: 5px;
    background: #fff;
  }
  .overlay {
    width: 100%;
    height: 91%;
    top: 64px;
  }
  body .options .option:not(.active) .label .info > div {
    right: 20px;
    top: -25px;
    transform: scaleX(-1);
    position: absolute;
  }
  body .options .option.active .label .info > div {
    left: 5px;
    opacity: 1;
  }
  body .options .option .label .info .main {
    font-weight: bold;
    font-size: 1rem;
  }
  body .options .option.active{
    background-position-x: 32%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .overlay {
    width: 100%;
    height: 87.3%;
    top: 130px;
  }
  .fullscreenMenu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    display: inline;
    font-size: 40px;
  }
  .fullscreenMenuName div {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .humburger {
    height: 48px;
    width: 48px;
    display: block !important;
    border: 1px solid red;
    border-radius: 5px;
  }
  .fullscreenMenuName a p {
    font-size: 20px;
  }
  .fullscreenMenu{
    top: 72px;
    right: 30px;
  }
  main {
    margin: 130px 0 50px 0 !important;
  }
  .design a{
    display: flex;
    justify-content: center;
  }
  body .options .option:not(.active) .label .info > div {
    right: 30px;
    top: -28px;
    transform: scaleX(-1);
    position: absolute;
  }
  .col{
    padding: 0 5px;
  }

}



.tempFullscreen{
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background: #fff;
  height: 100vh;
}
.tempHide{
  display: none !important;
}






/* yogamarketing css */
.section-top-border{
	border-top: 1px solid #efefef;
}
.section-bottom-border{
	border-bottom: 1px solid #efefef;
}
.section-white {
	padding: 25px 0 70px 0;
}
.section-grey{
	background: #dddddd;
	padding: 90px 0 100px 0;
}
.section-bg-2{
    /* background: url(../img/yoga2.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover; */
    width: 100%;
    position: relative;
    padding: 50px 0;
}
.section-bg-overlay{
	width: 100%;
	background: #8f8f8f;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.section-white-box{
	background-color: #fff;
	padding: 30px 40px 50px 40px;
}
.image-green-overlay {
    background: #e52a3d;
    opacity: 0.95;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.section-blue{
	background-color: #1e90ff;
	padding: 90px 0;
}
.section-white.no-padding, .section-grey.no-padding, .section-blue.no-padding {
	padding:0;
}
.section-white.medium-paddings, .section-grey.medium-paddings, .section-blue.medium-paddings {
	padding: 60px 0;
}
.section-white.small-paddings, .section-grey.small-paddings, .section-blue.small-paddings {
	padding: 30px 0;
}
.section-white.xsmall-paddings, .section-grey.xsmall-paddings, .section-blue.xsmall-paddings {
	padding: 10px 0;
}
.section-white.small-padding-top, .section-grey.small-padding-top, .section-blue.small-padding-top {
	padding: 30px 0 90px 0;
}
.section-white.small-padding-bottom, .section-grey.small-padding-bottom, .section-blue.small-padding-bottom {
	padding: 90px 0 70px 0;
}
.section-white.no-padding-bottom, .section-grey.no-padding-bottom, .section-blue.no-padding-bottom {
	padding: 90px 0 0 0;
}
.small-col-inside{
	max-width: 575px;
	padding-left: 55px;
}
.width-100{
	width: 100%;
}
.box-shadow {
	border-radius: 8px;
    box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.75);
}
/* margin and padding classes */
.margin-right-15 {
	max-width: 530px;
}
.margin-right-25 {
	margin-right: 25px !important;
}
.margin-left-20 {
	margin-left: 20px !important;
}
.margin-top-110 {
	margin-top: 110px !important;
}
.margin-top-100 {
	margin-top: 100px !important;
}
.margin-top-90 {
	margin-top: 90px !important;
}
.margin-top-80 {
	margin-top: 80px !important;
}
.margin-top-70 {
	margin-top: 70px !important;
}
.margin-top-60 {
	margin-top: 60px !important;
}
.margin-top-50 {
	margin-top: 50px !important;
}
.margin-top-40 {
	margin-top: 40px !important;
}
.margin-top-35 {
	margin-top: 35px !important;
}
.margin-top-30 {
	margin-top: 30px !important;
}
.margin-top-20 {
	margin-top: 20px !important;
}
.margin-top-10 {
	margin-top: 10px !important;
}
.margin-top-5 {
	margin-top: 5px !important;
}
.margin-bottom-0 {
	margin-bottom: 0px !important;
}
.margin-bottom-5{
	margin-bottom: 5px;
}
.margin-bottom-10 {
	margin-bottom: 10px !important;
}
.margin-bottom-20 {
	margin-bottom: 20px !important;
}
.margin-bottom-30 {
	margin-bottom: 30px !important;
}
.margin-bottom-40 {
	margin-bottom: 40px !important;
}
.margin-bottom-50 {
	margin-bottom: 50px !important;
}
.margin-bottom-60 {
	margin-bottom: 60px !important;
}
.margin-bottom-70 {
	margin-bottom: 70px !important;
}
.margin-bottom-80 {
	margin-bottom: 80px !important;
}
.margin-bottom-100 {
	margin-bottom: 100px !important;
}
.margin-bottom-120 {
	margin-bottom: 120px !important;
}
.margin-bottom-130 {
	margin-bottom: 120px !important;
}
.margin-bottom-140 {
	margin-bottom: 140px !important;
}
.padding-top-0 {
	padding-top: 0px !important;
}
.padding-top-10 {
	padding-top: 10px !important;
}
.padding-top-20 {
	padding-top: 20px !important;
}
.padding-top-25 {
	padding-top: 25px !important;
}
.padding-top-30 {
	padding-top: 30px !important;
}
.padding-top-35 {
	padding-top: 35px !important;
}
.padding-top-40 {
	padding-top: 40px !important;
}
.padding-top-45 {
	padding-top: 45px !important;
}
.padding-top-50 {
	padding-top: 50px !important;
}
.padding-top-60 {
	padding-top: 60px !important;
}
.padding-top-70 {
	padding-top: 70px !important;
}
.padding-top-80 {
	padding-top: 80px !important;
}
.padding-top-100 {
	padding-top: 100px !important;
}
.padding-top-120 {
	padding-top: 120px !important;
}
.padding-top-150 {
	padding-top: 150px !important;
}
.padding-bottom-70 {
	padding-bottom: 70px !important;
}
.padding-bottom-60 {
	padding-bottom: 60px !important;
}
.padding-bottom-50 {
	padding-bottom: 50px !important;
}
.padding-bottom-40 {
	padding-bottom: 40px !important;
}
.padding-bottom-30 {
	padding-bottom: 30px !important;
}
.padding-bottom-20 {
	padding-bottom: 20px !important;
}
.padding-bottom-10 {
	padding-bottom: 10px !important;
}
.padding-bottom-0 {
	padding-bottom: 0px !important;
}
.margin-top-0 {
	margin-top: 0px !important;
}
.padding-left-20{
	padding-left: 20px;
}
.mainTitle{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 400px;
  left: 0;
  background-color: rgb(255 255 255 / 68%);
  padding: 150px 50px;
}
.mainTitle h1{
  text-align: center;
  color: #d73630 !important;
  text-shadow: 2px 2px #fffbfb !important;
  margin-bottom: 0 !important;
  font-size: 3.2rem;
}
.home-section {
	position: relative;
	z-index: 999;
  padding-top: 150px;
  padding-bottom: 70px;
  background: url(./images/yoga6.jpg) center center no-repeat;
	background-size: cover;
	max-height: 520px;
  height: 100%;
  margin-top: 95px;
}
.home-section-overlay{
	width: 100%;
	opacity: 0.5;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
  background-color: #182430;
}
.home-section h1{
	margin-bottom: 15px;
  color: #fff;
  text-shadow: 1px 1px #454545;
}
.home-section p.hero-text{
	margin-bottom: 30px;
	font-size: 22px;
	line-height: 30px;
	font-weight: 400;
	color: #fff;
    text-shadow: 1px 1px #656565;
}
/* -----  Video Button ----- */
.video-icon {
	width: 84px;
	height: 84px;
	border:2px solid #fff;
	border-radius: 50% 50%;
	display: inline-block;
	background: transparent;
	transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
}
.video-icon:before {
    content: '';
    width: 84px;
    height: 84px;
    position: absolute;
    border: 1px solid #fff;
    border-radius: 100%;
    left: 50%;
    margin-left: -43px;
    bottom: 10%;
    margin-bottom: -9px;
    -webkit-animation: doublePulsation 1.9s ease infinite;
    animation: doublePulsation 1.9s ease infinite;
    z-index: 0;
}
@-webkit-keyframes doublePulsation {
     0% {-webkit-transform: scale(1.0, 1.0); opacity: 0.0;}
    50% {opacity: 0.55;}
    100% {-webkit-transform: scale(1.3, 1.3); opacity: 0.0;}
}
@keyframes doublePulsation {
     0% {-webkit-transform: scale(1.0, 1.0); opacity: 0.0;}
    50% {opacity: 0.55;}
    100% {-webkit-transform: scale(1.3, 1.3); opacity: 0.0;}
}
.video-icon:hover{
	background: #fff;
}
.video-icon:hover i{
	color: #4285f4;
}
.video-icon i {
	font-size: 40px;
	line-height: 84px;
	color:#fff;
	margin-left: 10px;
	transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
}
/* ========================================================== */
/* 			                05. FEATURES                      */
/* ========================================================== */
.services-wrapper{
	position: relative;
}
.main-services {
	position: relative;
    margin: 45px 5px 5px 5px;
    overflow: hidden;
    text-align: center;
	transition:all 1s;
}
.main-services:hover{
	transform: scale(1.025);
	transition:all 1s;
}
.main-services img{
	width: 100%;
	max-width: 200px;
    margin-bottom: 10px;
    border-radius: 50%;
}
.main-services h3 {
	margin-bottom: 5px;
}
.main-services h3 a{
	font-size: 22px;
	font-weight: 500;
	color: #e52a3d;
}
.main-services h4{
	margin-bottom: 10px;
	font-size: 20px;
	font-weight: 500;
}
.main-services p{
	padding: 0 10px;
	margin-bottom: 15px;
}
.main-services p.main-services-text{
	display: inline-block;
	text-align:center;
	max-width: 400px;
}
.main-services i {
    margin-bottom: 15px;
    font-size: 65px;
    color: #e52a3d;
}
/* FAQ */
.faq-box{
	margin: 30px 0 0 0;
	transition: all 1s;
}
.faq-box h4{
	font-size: 22px;
	margin-bottom: 10px;
}
.faq-box h5{
	margin-bottom: 7px;
}

/* ========================================================== */
/* 			                 08. REGISTER                     */
/* ========================================================== */
.register-form-wrapper{
	margin-top: 25px;
}
.register-form-wrapper h3{
	margin-bottom: 5px;
	font-size: 24px;
	font-weight: 500;
}
.register-form-wrapper p{
	margin-bottom: 25px;
	font-size: 17px;
}
input.register-input, select.register-input{
	width: 100%!important;
    height: 52px!important;
    padding-left: 8%!important;
    padding-right: 7%!important;
    margin-bottom: 15px!important;
    color: #555!important;
    border: 1px solid #f1f1f1!important;
    background-color: #f9f9f9!important;
    font-size: 16px!important;
    border-radius: 5px 5px 5px 5px!important;
}

input.register-submit{
	background: #e52a3d;
    border: none;
    color: #fff;
    letter-spacing: 1px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    max-width: 535px;
    padding: 16px 0;
    text-transform: uppercase;
    -webkit-border-radius: 3px 3px;
    -moz-border-radius: 3px 3px;
    border-radius: 3px 3px;
    transition: all .50s ease-in-out;
    -moz-transition: all .50s ease-in-out;
    -webkit-transition: all .50s ease-in-out;
}
input.register-submit:hover{
	background:#d82032;
	transition: all .50s ease-in-out;
	-moz-transition: all .50s ease-in-out;
	-webkit-transition: all .50s ease-in-out;
}
#register-form input.register-input:focus, #register-form select.register-input:focus {
  border-color:#656565!important;
  color: #454545!important;
  outline: none;
}
#register-form input.register-input:focus, #register-form select.register-input:focus {
  border-color:#656565!important;
  color: #454545!important;
  outline: none;
}
p.register_success_box{
	display:inline-block;
	color:#fff;
	border: 1px solid #eee;
	padding: 25px 25px;
	font-size:19px;
	text-shadow: none;
	font-style:italic;
	border-radius: 8px 8px;
	margin:15px 0;
}
p.register-form-terms{
	margin: 10px 0 5px 0;
	font-size: 14px;
	color: #7c8595;
    text-shadow: none;
}
/* ========================================================== */
/* 			               10. OTHER ELEMENTS                 */
/* ========================================================== */
/* a) Buttons */
.btn-red {
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	line-height: 22px;
	font-weight: 500;
	color: #fff;
	background-color: #e52a3d;
	border: 1px solid #e52a3d;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.27);
    text-shadow: 1px 1px 1px rgba(0,0,0,0.25);
    letter-spacing: 1px;
	border-radius: 5px 5px;
	margin: 15px 10px 0 0;
	padding: 18px 25px;
	display: inline-block;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
}
.btn-red:hover{
	box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.5);
	color: #fff!important;
	background:#d82032;
}
.btn-red.small {
	font-size: 16px;
	padding: 14px 20px;
}
a.btn-red:visited, a.btn-red:focus{
	color: #fff!important;
}
/* -----  b) Gallery PopUp ----- */
.popup-wrapper{
	display:block;
	position:relative;
	overflow:hidden;
}
.popup-gallery{
	display:inline-block;
	position:relative;
}
.first-gallery{
	margin-bottom:15px;
}
.popup-gallery img {
	position:relative;
	z-index:10;
	width: 100%;
	height: auto;
	-webkit-border-radius: 5px 5px 0 0 !important;
    -moz-border-radius: 5px 5px 0 0 !important;
    border-radius: 5px 5px 0 0 !important;
}
.popup-gallery:hover img {
	opacity: 0.25;
	-webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
}
.popup-gallery a span.eye-wrapper,
.popup-gallery a span.eye-wrapper2 {
	background-color:#000;
	position: absolute;
	display: block;
	overflow:hidden;
	z-index: 2;
	height:100%!important;
	top: 0%;
	margin-top: 0px;
	left: 0%;
	right: 0%;
	font-size: 22px;
	color:#fff;
	text-align: center;
	font-weight:300;
	opacity: 0;
	-webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
}
.popup-gallery-rounded a span.eye-wrapper,
.popup-gallery-rounded a span.eye-wrapper2 {
	-webkit-border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
}
.popup-gallery a span.eye-wrapper i.eye-icon, .popup-gallery a span.eye-wrapper2 i.eye-icon{
	position: absolute;
	display: inline-block;
	font-size:36px;
	z-index: 3;
	top: 50%!important;
	margin-top: -18px!important;
	left: 0%;
	right: 0%;
}
.popup-gallery a:hover span{
	opacity: 1;
}
.popup-gallery img,
.popup-gallery a span {
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}
.gallery-item{
	border-radius: 5px 5px;
}
.gallery-insta{
	display: inline-block;
	margin-bottom: 15px;
}
.gallery-insta img{
	border-radius: 5px 5px 5px 5px !important;
}
.portfolio-pic{
	display: block;
	margin-bottom: 10px;
}
p.follow-instagram{
	font-size: 20px;
}
p.follow-instagram a{
	color: #e52a3d;
}
p.follow-instagram a:hover{
	color: #e52a3d;
}
/* c) Testimonials */
.testim-inner{
	position: relative;
	text-align: center;
    transition: all 1s;
}
.testim-inner img{
	position: relative;
	display: inline-block;
	max-width: 110px;
	border:1px solid #e5efff;
	border-radius: 50% 50%;
	padding: 5px 5px;
	background-color: #fff;
}
.testim-inner h6{
	margin-bottom: 5px;
	font-size: 19px;
	line-height: 26px;
	font-weight: 500;
	color: #fff;
	font-family: 'Roboto', sans-serif;
}
.testim-inner p{
	font-size: 18px;
	line-height: 28px;
	color: #fff;
	font-style: italic;
	margin-top: 20px;
	margin-bottom: 5px;
	text-shadow: 1px 1px #656565;
}
.testim-rating {
	margin-bottom: 30px;
}
.testim-rating i{
	color: #fdcc28;
}
.testim-inner i.quote{
	font-size: 40px;
	margin-bottom: 5px;
	color: #fff;
	text-shadow: 1px 1px #656565;
}
/* d) Accordion Tabs */
.card-header {
    padding: 6px 0 9px 0;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 1px solid #999;
}
.card-header h5 .btn-link {
	padding: 0;
	font-size: 19px;
    font-weight: 500;
    background-color: transparent;
}
.card-header h5 .btn-link:hover, .card-header h5 .btn-link:focus {
	text-decoration: none;
}
.card-body {
    padding: 10px 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 17px;
}
/* e) Carousel */
.carousel-indicators {
    bottom: -15px;
}
.carousel-indicators li {
    position: relative;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 25px;
    height: 7px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #cecece;
    border-radius:5px 5px;
}
.carousel-indicators.testimonials-indicators {
    bottom: -40px;
}
.carousel-indicators.testimonials-indicators li {
    background-color: #cecece;
}
.carousel-indicators.testimonials-indicators .active {
    background-color: #e52a3d;
}
.gallery-show {
    margin: 20px 0 40px 0;
    max-width: 100%;
    border-radius: 8px 8px;
    border: 8px solid rgba(255, 255, 255, 0.8);
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
}
/* f) Features List */
ul.benefits {
    margin-bottom: 20px;
}
ul.benefits li {
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 8px;
}
ul.benefits.white li {
	color: #fff;
}
ul.benefits li i {
    font-size: 18px;
    line-height: 18px;
    margin-right: 1px;
    color: #e52a3d;
}
ul.benefits.white li i {
    color: #fff;
}
.no-padding-bottom{
	padding-bottom: 0;
}
/* ========================================================== */
/* 			             08. MEDIA QUERIES                    */
/* ========================================================== */
@media (max-width: 991px) {
	.navbar-fixed-top.opaque {
	    padding: 0;
	}
	.navbar-fixed-top .navbar-nav > li.discover-link {
		margin-top: 10px;
	}
	.navbar-fixed-top .navbar-nav > li > a.discover-btn {
	    padding: 8px 12px!important;
	    margin-left: 20px;
	    font-size: 17px!important;
	}
}
@media (max-width: 768px) {
	.navbar-fixed-top {
	    background-color: rgba(0, 0, 0, 0.95)!important;
	}
	.home-section {
		padding-top: 170px;
	  	padding-bottom: 100px;
	}
    .hero-image{
    	margin-top: 70px;
    }
    .padding-top-120 {
	    padding-top: 30px !important;
	}
	.footer {
	    padding-top: 30px;
	}
	.footer-top i {
		display: block;
	    margin-top: 50px!important;
	}
	ul.footer_social li a i {
	    margin: 0 8px!important;
	}
	.top-margins-images{
		margin-top: 50px;
	}
	.margin-right-15{
		margin-bottom: 40px;
	}
}
@media (max-width: 375px) {
	.navbar-toggle {
	    margin-bottom: 0;
	    border: 1px solid #9f9f9f!important;
	}
	.home-section h1 {
	    font-size: 38px;
	    line-height: 44px;
	}
	.section-white {
	    padding: 60px 0 70px 0!important;
	}
	.section-white.small-padding-bottom, .section-grey.small-padding-bottom {
	    padding: 60px 0 50px 0!important;
	}
	.section-bg-2{
	    background-attachment: inherit;
	}
	.margin-left-20{
		margin-left: 1px!important;
	}
	.register-form-terms{
		padding-bottom: 30px;
	}
	.services-wrapper{
		margin-top: 30px;
	}
	.small-col-inside{
		padding-right: 25px;
		padding-left: 25px;
	}
	input.register-input, select.register-input{
	    padding-left: 12%!important;
	}

}

.box-column{
	box-shadow: 0 0 5px #00000036;
	border-radius: 10px;
	overflow: hidden;
	transition: all 0.2s ease-out;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-ms-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	margin-bottom: 15px;
}
.box-column:hover{
	box-shadow: 0 5px 10px #00000061;
	transition: all 0.2s ease-out;
}
.box-top {
    background: #dedede;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.box-top.instagram {
  background: linear-gradient(45deg, #f2935b, #6f49c0);
  background: url(./images/website.jpg);
  background-size: cover;
  background-position: bottom;
  min-height: 200px;
}

.box-top.twitter {
  background: linear-gradient(45deg, #cae1f3, #55aded);
  background: url(./images/logo.jpg);
  background-size: cover;
  background-position: center;
  min-height: 200px;
}

.box-top.facebook {
  background: linear-gradient(45deg, #95a2c1, #3b579d);
  background: url(./images/seo.jpg);
  background-size: cover;
  background-position: center;
  min-height: 200px;
}

.box-top.automation {
  background: linear-gradient(45deg, #f2935b, #6f49c0);
  background: url(./images/automation.jpg);
  background-size: cover;
  background-position: center;
  min-height: 200px;
}

.box-bottom {
    background: #FFF;
    padding: 15px 0 0 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.box-bottom .box-text {
    padding: 15px;
	text-align: left;
	color: #333;
}
.box-bottom .box-text .moreBtn{
	display: flex;
	color: red;
	margin-bottom: 0;
  margin-top: 10px;
}
.box-bottom .box-text .moreBtn ion-icon{
	padding: 5px;
    font-size: 18px;
}

.box-bottom .box-title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    padding: 0 15px;
    text-overflow: ellipsis;
    height: 30px;
    overflow: hidden;
}
.box-top{
  min-height: 250px;
}

.discover-btn{
	background: #fff;
    padding: 7px 15px;
    border-radius: 5px;
    color: #dc3545;
    font-weight: 600;
	margin-left: 10px;
}
.discover-btn:hover{
	background: #ddd;
    color: #dc3545;
}
.opaque .discover-btn{
	background: #dc3545;
    padding: 7px 15px;
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
	margin-left: 10px;
}
.footer_social a i {
    padding: 0;
	color: #333;
    font-size: 26px!important;
    line-height: 26px!important;
    transition: all .5s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -webkit-transition: all .25s ease-in-out;
}
.overlayText h2,.overlayText p{
	color: #fff;
	text-align: center;
}
.overlayText .btn{
	background-color: #fff;
	color: #dc3545;
	padding: 10px 15px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}

.buttonOutline{
  position: relative;
  display: inline-block;
  padding: 10px 40px;
  color: white !important;
  background-color: transparent !important;
  border: 2px solid #fff;
  letter-spacing: 0.05ex;
  font-size: 14px;
  font-weight: 700;
  border-radius: 100px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 1;
  -webkit-box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.2);
}
.buttonOutline:hover{
  color: #333 !important;
  background-color: white !important;
}

.btnPrimary{
	background: #dc3545;
  padding: 7px 15px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
	margin-left: 20px;
	margin-top: 35px;
}
.about-text ul{
  padding-left: 30px;
  list-style: disc;
  margin: 35px 0;
}
.about-text ul li{
  list-style: disc;
}

.gotoService a ion-icon{
  color: #fff;
  font-size: 45px;
  margin-top: 35px;
  transition: 0.2s all ease-out;
  -webkit-transition: 0.2s all ease-out;
  -moz-transition: 0.2s all ease-out;
  -ms-transition: 0.2s all ease-out;
  -o-transition: 0.2s all ease-out;
}
.gotoService a ion-icon:hover{
  color: #333;
  background: #fff;
  border-radius: 50%;
  transition: 0.2s all ease-out;
}

.yogaMainSec a{
  width: 215px;
}
.innerDiv ul{
  list-style: disc;
  padding-left: 30px;
}
.innerDiv ul li{
  list-style: disc;
}
.innerDiv{
  padding: 50px 30px;
  color: #fff;
}
.innerDiv h3,.innerDiv p{
  color: #fff;
}
.colorDark{
  background-color: #182430;
  color:#fff;
}
.colorLight{
  background-color: #888b7a;
  color:#fff;
}
.calendly-inline-widget{
  height: 100vh;
    width: 100%;
}

.button {
  position: relative;
  display: inline-block;
  padding: 10px 40px;
  color: white !important;
  letter-spacing: 0.05ex;
  font-size: 14px;
  font-weight: 700;
  background-color: #d73630;
  border-radius: 100px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 1;
  -webkit-box-shadow: 0 0 0 5px rgb(215 54 48 / 40%);
  box-shadow: 0 0 0 5px rgb(215 54 48 / 40%);
  margin-top: 40px;
  border: none;
}
.button:hover {
  color: white;
  text-decoration: none;
  background-color: #97221e;
  -webkit-box-shadow: 0 0 0 7px rgb(215 54 48 / 45%);
  box-shadow: 0 0 0 7px rgb(215 54 48 / 45%);
}
.navbar-brand img{
  width: 100px !important;
}
img{
  display: block;
  width: 100%;
}
.heroText{
  color: #fff;
  font-size: 40px;
  line-height: 1.5;
  margin-bottom: 25px;
}
.titleText{
  font-size: 36px;
  font-family: 'Arvo', serif;
}